@import "bootstrap/scss/bootstrap.scss";

$sizes: (
    25: 25%,
    50: 50%,
    75: 75%,
    99: 99%,
    100: 100%,
);

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $prop, $abbrev in (width: w, height: h) {
      @each $size, $length in $sizes {
        .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
      }
    }
  }
}

// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.cdk-overlay-container { z-index: 9999; }

.app-bg-color-light-orange {
  background-color: #FFE1B2;
}

.app-bg-color-orange {
  background-color: #FFB340;
}

.app-bg-color-dark-orange {
  background-color: #FF9900;
}

